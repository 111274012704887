<template>
	<div class="main-content">
		<div class="meunTop">
			<div class="meunLine"></div>
			<div class="meunTitle">供应商列表</div>
		</div>
		<div class="flex">
			<div>
				<el-form class="search" label-position='left' ref="seachForm" :model="seachForm" size="large">
					<el-form-item>
						<el-input :maxlength="20" v-model="seachForm.name" clearable placeholder="请输入供应商名称"></el-input>
					</el-form-item>
					<el-form-item>
						<el-select  v-model="seachForm.state" clearable placeholder="请选择状态">
							<el-option v-for="item in roleIdsoption" :key="item.value" :label="item.label" :value="item.value"> </el-option>
						</el-select>
					</el-form-item>
					<el-form-item>
						<el-button type="primary" @click="seachSumbit(seachForm)">搜索</el-button>
					</el-form-item>
				</el-form>
			</div>
			<el-button @click="addshow=true;addtype=1;resource='1'">添加供应商</el-button>
		</div>
		<div class="table-content">
			<el-table :cell-style="{ textAlign: 'center' }" :header-cell-style="{textAlign: 'center',backgroundColor:'#F5F7FA',color:'#333'}" :border="true" :data="data" style="width: 100%">
				<el-table-column prop="supplierName" label="供应商名称" width="200">
				</el-table-column>
				<el-table-column prop="supplierKeyId" label="供应商KEYID" width="200">
				</el-table-column>
				<el-table-column prop="integralBalance" label="积分余额" width="150">
				</el-table-column>
				<el-table-column prop="drawAmount" label="累计提现金额" width="150">
				</el-table-column>
				<el-table-column prop="jumpUrl" label="跳转链接/指定地址" width="200">
					<template slot-scope="scope">
						<div style="display: flex;justify-content: center;">
							<p v-if="scope.row.jumpUrl">{{scope.row.jumpUrl}}</p>
							<p v-else>{{scope.row.indexUrl}}</p>
						</div>
					</template>
				</el-table-column>
				<el-table-column prop="contacts" label="联系人" width="150">
				</el-table-column>
				<el-table-column prop="phone" label="手机号" width="200">
				</el-table-column>
				<el-table-column prop="createTime" label="注册时间" width="170">
				</el-table-column>
				<el-table-column prop="sort" label="排序值" width="100">
				</el-table-column>
				<el-table-column prop="operaStatus" label="状态" width="150">
					<template slot-scope="scope">
						<div style="display: flex;justify-content: center;">
							<div style="display: flex;">
								<p v-if="scope.row.operaStatus==0">正常</p>
								<p v-else-if="scope.row.operaStatus==1">冻结</p>
								<p v-else>已失效</p>
							</div>
						</div>
					</template>
				</el-table-column>
				<el-table-column fixed="right" label="操作" width="250">
					<template style="display: flex;" slot-scope="scope">
						<div v-if="data[scope.$index].operaStatus != 2">
							<el-button @click.native.prevent="updataclick(scope.$index, data)" type="text" size="small">
								编辑
							</el-button>
							<el-button @click.native.prevent="deleteList(scope.$index, data, 'freeze')" v-if="data[scope.$index].operaStatus == 0" type="text" size="small">
								冻结
							</el-button>
							<el-button @click.native.prevent="deleteList(scope.$index, data, 'restore')" v-if="data[scope.$index].operaStatus == 1" type="text" size="small">
								恢复
							</el-button>
							<el-button @click.native.prevent="deleteList(scope.$index, data, 'cancel')" type="text" size="small">
								取消入驻
							</el-button>
						</div>
						<div v-else>
							<el-button disabled type="text" size="small">
								编辑
							</el-button>
							<el-button disabled type="text" size="small">
								冻结
							</el-button>
							<el-button disabled type="text" size="small">
								取消入驻
							</el-button>
						</div>
					</template>
				</el-table-column>
			</el-table>
		</div>

		<!-- 新增的弹窗 -->
		<el-dialog :visible.sync="addshow" width="700px" :before-close="handleClose" :title="addtype == 1 ? '添加供应商' : '修改供应商'">
			<el-form class="form_box" :model="sizeForm" label-position='left'  ref="sizeForm"  size="small">
				<el-form-item>
                    <template slot-scope="scope">
						<div style="display:flex;" :src="scope.row">
							<p style="min-width:100px;text-align:right;margin-right:10px;"><span style="color:red">*</span>供应商名称：</p>
							<el-input :maxlength="30" v-model="sizeForm.supplierName" clearable placeholder="请输入供应商名称"></el-input>
						</div>
					</template>
				</el-form-item>
				<el-form-item>
                    <template slot-scope="scope">
						<div style="display:flex;" :src="scope.row">
							<p style="min-width:100px;text-align:right;margin-right:10px;">跳转类型：</p>
							<el-radio-group v-model="resource" @change="radioChange"  style="display:flex;align-items:center;">
                                <el-radio label="1">跳转小程序</el-radio>
                                <el-radio label="2">跳转外链</el-radio>
                            </el-radio-group>
						</div>
					</template>
				</el-form-item>
				<el-form-item v-if="resource == '2'">
                    <template slot-scope="scope">
						<div style="display:flex;" :src="scope.row">
							<p style="min-width:100px;text-align:right;margin-right:10px;"><span style="color:red">*</span>链接地址：</p>
							<el-input :maxlength="100" v-model="sizeForm.jumpUrl" clearable placeholder="请输入链接地址"></el-input>
						</div>
					</template>
				</el-form-item>
				<el-form-item v-if="resource == '1'">
                    <template slot-scope="scope">
						<div style="display:flex;" :src="scope.row">
							<p style="min-width:100px;text-align:right;margin-right:10px;"><span style="color:red">*</span>APPID：</p>
							<el-input :maxlength="100" v-model="sizeForm.appId" clearable placeholder="请输入APPID"></el-input>
						</div>
					</template>
				</el-form-item>
				<el-form-item v-if="resource == '1'">
                    <template slot-scope="scope">
						<div style="display:flex;" :src="scope.row">
							<p style="min-width:100px;text-align:right;margin-right:10px;"><span style="color:red">*</span>指定地址：</p>
							<el-input :maxlength="100" v-model="sizeForm.indexUrl" clearable placeholder="请输入指定地址"></el-input>
						</div>
					</template>
				</el-form-item>
				<el-form-item>
                    <template slot-scope="scope">
						<div style="display:flex;" :src="scope.row">
							<p style="min-width:100px;text-align:right;margin-right:10px;"><span style="color:red">*</span>联系人：</p>
							<el-input :maxlength="30" v-model="sizeForm.contacts" clearable placeholder="请输入联系人"></el-input>
						</div>
					</template>
				</el-form-item>
				<el-form-item>
                    <template slot-scope="scope">
						<div style="display:flex;" :src="scope.row">
							<p style="min-width:100px;text-align:right;margin-right:10px;"><span style="color:red">*</span>手机号：</p>
							<el-input type="text" v-model="sizeForm.phone" :maxlength="11" clearable placeholder="请输入手机号"  oninput="if(value.length>11)value=value.slice(0,11)"></el-input>
						</div>
					</template>
				</el-form-item>
				<el-form-item>
                    <template slot-scope="scope">
						<div style="display:flex;" :src="scope.row">
							<p style="min-width:100px;text-align:right;margin-right:10px;"><span style="color:red">*</span>LOGO：</p>
                            <el-upload class="avatar-uploader" action="api/web/tool/file/upload" :headers="headers" :show-file-list="false" :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
								<img v-if="sizeForm.imgUrl" :src="sizeForm.imgUrl" class="avatar">
								<i v-else class="el-icon-plus avatar-uploader-icon photo"></i>
							</el-upload>
						</div>
					</template>
				</el-form-item>
				<el-form-item>
                    <template slot-scope="scope">
						<div style="display:flex;" :src="scope.row">
							<p style="min-width:100px;text-align:right;margin-right:10px;"><span style="color:red">*</span>排序值：</p>
							<el-input type="number" min="0"  oninput="if(value.length>4)value=value.slice(0,4)"  @keyup.native="keyVerify($event)" v-model="sizeForm.sort" @blur="sortBlur"></el-input>
						</div>
					</template>
				</el-form-item>
				<el-form-item size="large" style="display:flex;justify-content:flex-end;">
					<el-button @click.native.prevent='handleClose'>取消</el-button>
					<el-button v-if="addtype==1" type="primary" @click.native.prevent="submitForm('sizeForm')">添加</el-button>
					<el-button v-if="addtype==2" type="primary" @click.native.prevent="onUpdata('sizeForm')">修改</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>

		<div class="">
			<el-pagination @current-change="handleCurrentChange" :current-page.sync="currentPage"
				:page-count="pageCount" :page-size="pageSize" layout="total, prev, pager, next" :total="total">
			</el-pagination>
		</div>
	</div>
</template>

<script>
	import {
		imageUrl
	} from '@/config/public';
	import {
		supplierList,supplierAdd,supplierUpdate,supplierDelete
	} from "@/api/platform/index";
	export default {
		name: "supplierList",
		data() {
			return {
				seachForm:{
					name: '',
					state: '',
				},
				resource:'1',
				image_url: imageUrl,
				roleIdsoption: [{label: '正常',value: 0},{label: '冻结',value: 1},{label: '已失效',value: 2}],
				headers: {
					Authorization: this.$store.state.user.Token
				},
				dialogVisible: false,
				data: [],
				addshow: false,
				sizeForm: {
					supplierName: '',
					contacts: '',
					phone: '',
					appId: '',
					jumpUrl: '',
					indexUrl:'',
					sort: 1,
					imgUrl:''
				},
				page: 1,
				pageSize: 10,
				total: 0, //总数据
				currentPage: 0, //当前页页数
				pageCount: 0, //总页数
				addtype: '',
				id: 0,
			};
		},
		mounted() {
			let that = this;
			that.list();
		},
		created() {
		},
		methods: {
			//radio切换
			radioChange(e){
			
			},
			//搜索提交
			seachSumbit(formName){
				this.page = 1
				this.list();
			},
			//获取列表
			list() {
				supplierList({
					page: this.page,
					size: this.pageSize,
					operaStatus: this.seachForm.state,
					supplierName: this.seachForm.name
				}).then(res => {
					console.log(res)
					this.data = res.data.records;
					this.total = res.data.total;
					this.currentPage = res.data.current;
					this.pageCount = res.data.pages;
					if (res.data.current > res.data.pages && res.data.pages != 0) {
						this.page = res.data.pages;
						this.list();
					}
				})
			},
			//分页
			handleCurrentChange(val) {
				// console.log(`当前页: ${val}`);
				this.page = val;
				this.list();
			},
				// 排序值验证
			sortBlur(){
				let that = this;
				if(Number(that.sizeForm.sort) >= 0){
					that.sizeForm.sort = that.sizeForm.sort;
				}else{
					that.$message.error('排序值不能为负数');
				}
			},
			//编辑提交
			onUpdata(formName){
				if(this.addshow){
					this.$refs[formName].validate((valid) => {
						if (valid) {
							if(this.$refs[formName].model.supplierName == ''){
								this.$message.warning('供应商名称不能为空');
							}else if(this.$refs[formName].model.contacts == ''){
								this.$message.warning('联系人不能为空');
							}else if(this.$refs[formName].model.phone == ''){
								this.$message.warning('手机号不能为空');
							}else if(!(/^1(3|4|5|6|7|8|9)\d{9}$/.test(this.$refs[formName].model.phone))){
								this.$message.warning('手机号不合法');
							}else if(this.$refs[formName].model.imgUrl == ''){
								this.$message.warning('LOGO图片不能为空');
							}else if(this.resource == '1' && this.$refs[formName].model.appId == ''){
								this.$message.warning('请输入APPID');
							}else if(this.resource == '1' && this.$refs[formName].model.indexUrl == ''){
								this.$message.warning('请输入指定地址');
							}else if(this.resource == '2' && this.$refs[formName].model.jumpUrl == ''){
								this.$message.warning('请输入跳转地址');
							}else if(this.$refs[formName].model.sort.toString() == ''){
								this.$message.warning('排序值不能为空');
							}else if(Number(this.$refs[formName].model.sort) < 0){
								this.$message.warning('排序值不能为负数');
							}else{
								if(this.resource == '2'){
									this.$refs[formName].model.appId = '';
									this.$refs[formName].model.indexUrl = '';
								}else if(this.resource == '1'){
									this.$refs[formName].model.jumpUrl = ''
								}
								supplierUpdate({
									supplierName: this.$refs[formName].model.supplierName,
									contacts: this.$refs[formName].model.contacts,
									phone: this.$refs[formName].model.phone,
									appId:this.$refs[formName].model.appId,
									jumpUrl: this.$refs[formName].model.jumpUrl,
									indexUrl: this.$refs[formName].model.indexUrl,
									sort: this.$refs[formName].model.sort,
									id: this.id,
									imgUrl:this.$refs[formName].model.imgUrl,
								}).then(res=>{
									if (res.code == 200) {
										this.$message.success('编辑成功');
										this.sizeForm = {
											supplierName: '',
											contacts: '',
											phone: '',
											appId: '',
											jumpUrl: '',
											indexUrl:'',
											sort: 1,
											imgUrl:''
										}
										this.addshow = false
										this.page = 1
										this.list();
									} else {
										this.$message.error(res.msg);
									}
								}).catch(err => {
									this.$message.error(err.msg);
								})
							}
						} else {
							return false;
						}
					});
				}
			},
			//冻结/恢复/取消入驻
			deleteList(cur, tab, operate) {
				this.$confirm('你确定要' + (operate == 'freeze' ? '冻结【' : operate == 'restore' ? '恢复【' : '取消入驻【') + tab[cur].supplierName + '】吗?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					supplierDelete(operate + '/' + tab[cur].id).then(res => {
						if (res.code == 200) {
							this.$message.success('操作成功');
							this.page = 1
							this.list();
						} else {
							this.$message.error(res.msg);
						}
					}).catch(err => {
						this.$message.error(err.msg);
					})
				}).catch(_ => {})
			},

			//图片上传
			handleAvatarSuccess(res, file) {
				console.log(res)
				if(res.code == 200){
					this.sizeForm.imgUrl = res.data.path;
				}else{
					this.$message.error(res.msg);
				}
			},
			//图片验证
			beforeAvatarUpload(file) {
				const isJPG = (file.type === 'image/jpeg' || file.type === 'image/png');
				// const isLt2M = file.size / 1024 / 1024 < 2;
				if (!isJPG) {
					this.$message.error('Banner图片只能是 JPG 或 PNG 格式!');
				}
				// if (!isLt2M) {
				// 	this.$message.error('上传头像图片大小不能超过 2MB!');
				// }
				// return isJPG && isLt2M;
				return isJPG
			},
			//关闭提醒
			handleClose(done) {
				this.sizeForm = {
					supplierName: '',
					contacts: '',
					phone: '',
					appId: '',
					jumpUrl: '',
					indexUrl:'',
					sort: 1,
					imgUrl:''
				}
				this.addshow = false
			},
			//编辑按钮点击
			updataclick(i,tab){
				console.log(tab[i])
				this.sizeForm = {
					supplierName: tab[i].supplierName,
					contacts: tab[i].contacts,
					phone: tab[i].phone,
					appId: tab[i].appId,
					jumpUrl: tab[i].jumpUrl,
					indexUrl: tab[i].indexUrl,
					sort: tab[i].sort,
					imgUrl:tab[i].imgUrl
				}
				this.id = tab[i].id
				if(this.sizeForm.appId != null && this.sizeForm.appId.length > 0){
					this.resource = '1'
				}else{
					this.resource = '2'
				}
				this.addtype = 2
				this.addshow = true
			},
			//添加提交
			submitForm(formName) {
				if(this.addshow){
					this.$refs[formName].validate((valid) => {
						if (valid) {
							if( this.$refs[formName].model.supplierName == ''){
								this.$message.warning('供应商名称不能为空');
							}else if(this.$refs[formName].model.contacts == ''){
								this.$message.warning('联系人不能为空');
							}else if(this.$refs[formName].model.phone == ''){
								this.$message.warning('手机号不能为空');
							}else if(!(/^1(3|4|5|6|7|8|9)\d{9}$/.test(this.$refs[formName].model.phone))){
								this.$message.warning('手机号不合法');
							}else if(this.$refs[formName].model.imgUrl == ''){
								this.$message.warning('LOGO图片不能为空');
							}else if(this.resource == '1' && this.$refs[formName].model.appId == ''){
								this.$message.warning('请输入APPID');
							}else if(this.resource == '1' && this.$refs[formName].model.indexUrl == ''){
								this.$message.warning('请输入指定地址');
							}else if(this.resource == '2' && this.$refs[formName].model.jumpUrl == ''){
								this.$message.warning('请输入跳转地址');
							}else if(this.$refs[formName].model.sort.toString() == ''){
								this.$message.warning('排序值不能为空');
							}else if(Number(this.$refs[formName].model.sort) < 0){
								this.$message.warning('排序值不能为负数');
							}else{
								if(this.resource == '2'){
									this.$refs[formName].model.appId = '';
									this.$refs[formName].model.indexUrl = '';
								}else if(this.resource == '1'){
									this.$refs[formName].model.jumpUrl = ''
								}
								supplierAdd({
									supplierName: this.$refs[formName].model.supplierName,
									contacts: this.$refs[formName].model.contacts,
									phone: this.$refs[formName].model.phone,
									appId:this.$refs[formName].model.appId,
									jumpUrl: this.$refs[formName].model.jumpUrl,
									indexUrl: this.$refs[formName].model.indexUrl,
									sort: this.$refs[formName].model.sort,
									imgUrl:this.$refs[formName].model.imgUrl,
								}).then(res=>{
									console.log(res)
									if(res.code == 200){
										this.$message.success('添加成功,请等待管理员审核');
										this.sizeForm = {
											supplierName: '',
											contacts: '',
											phone: '',
											appId: '',
											jumpUrl: '',
											indexUrl:'',
											sort: 1,
											imgUrl:''
										}
										this.addshow = false
										this.page = 1
										this.list();
									}else{
										this.$message.error(res.msg);
									}
								}).catch(err => {
									this.$message.error(err.msg);
								})
							}
						} else {
							return false;
						}
					});
				}
			},
		}
	}
</script>

<style scoped lang="scss">
	.table-content {margin-top: 20px;}
	.active {color: #049EC4;}
	.form_box{padding:0 50px;}
	.avatar{width:150px;}
	.photo{width:150px;height:150px;border:1px solid #eee;display:flex;align-items: center;justify-content:center;font-size:40px;color:#ccc;}
	.flex{display:flex;align-items:center;justify-content:space-between;}
	.search{display:flex;align-items:center;}
	.search .el-form-item{margin:0 20px 0 0 !important;}

	.meunTop{display:flex;align-items:center;margin-bottom:20px;}
	.meunLine{width:3px;height:20px;background-color:#FFA218;}
	.meunTitle{font-size:20px;margin-left:10px;}
</style>
